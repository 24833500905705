import { motion } from "framer-motion";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import GifVideo from "../../../../src/components/GifVideo";
import * as React from 'react';
export default {
  motion,
  getImage,
  GatsbyImage,
  GifVideo,
  React
};